import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {

    window.Form = new App({
        noResults: "\u0644\u0645 \u064a\u062a\u0645 \u0627\u0644\u0639\u062b\u0648\u0631 \u0639\u0644\u0649 \u062a\u0637\u0627\u0628\u0642",
        messages: {
            required: '\u0627\u0645\u0644\u0623 \u0627\u0644\u062d\u0642\u0644',
            phone: {
                incorrect: '\u0631\u0642\u0645 \u0627\u0644\u0647\u0627\u062a\u0641 \u063a\u064a\u0631 \u0635\u062d\u064a\u062d'
            },
            email: {
                incorrect: '\u062a\u0646\u0633\u064a\u0642 \u0639\u0646\u0648\u0627\u0646 \u062e\u0627\u0637\u0626',
                symbolNotFound: '\u064a\u062c\u0628 \u0623\u0646 \u064a\u062d\u062a\u0648\u064a \u0627\u0644\u0639\u0646\u0648\u0627\u0646 \u0639\u0644\u0649 \u0627\u0644\u062d\u0631\u0641 \u0022\u0040\u0022 \u002e \u0627\u0644\u0639\u0646\u0648\u0627\u0646 \u064a\u0641\u062a\u0642\u062f \u0627\u0644\u0631\u0645\u0632 \u0022\u0040\u0022',
                addressIsNotFull: '\u0623\u062f\u062e\u0644 \u062c\u0632\u0621 \u0627\u0644\u0639\u0646\u0648\u0627\u0646 \u0628\u0639\u062f \u0627\u0644\u062d\u0631\u0641 \u0022\u0040\u0022 \u002e \u0627\u0644\u0639\u0646\u0648\u0627\u0646 \u0644\u064a\u0633 \u0645\u0645\u062a\u0644\u0626\u064b\u0627\u002e'
            },
            password: {
                minLength: '\u0627\u0644\u062d\u062f \u0627\u0644\u0623\u062f\u0646\u0649 \u0644\u0639\u062f\u062f \u0627\u0644\u0623\u062d\u0631\u0641 \u0627\u0644\u0645\u0633\u0645\u0648\u062d \u0628\u0647 \u0647\u0648 \u0036\u002e \u0627\u0644\u0622\u0646 %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u0643\u0644\u0645\u0627\u062a \u0627\u0644\u0645\u0631\u0648\u0631 \u0627\u0644\u062a\u064a \u062a\u0645 \u0625\u062f\u062e\u0627\u0644\u0647\u0627 \u063a\u064a\u0631 \u0645\u062a\u0637\u0627\u0628\u0642\u0629'
            },
            ofertaAgreement: {
                notChecked: '\u0623\u0643\u062f \u0642\u0628\u0648\u0644\u0643 \u0644\u0644\u0642\u0648\u0627\u0639\u062f'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});